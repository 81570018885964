.banner-container {
    background-color: #1b1f23;
    color: #eeecec;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-family: Questrial, sans-serif;
     padding: 2px 0;
}