.answer-textarea {
    width: 100%;
}

.question-button {
    color: #00AEEF;
    padding: 0;
    border: none;
    background: none;
}

.answer-section-hide{
    display:none;
}

.answer-section-show{
    display:block;
}

.code-style {
    display: block; 
    overflow-x: auto;
}

.answer-row {
    margin-bottom: 30px;
}

.question-text {
    font-weight: bold;
}

.first-row{
    margin-top: 10px;
}

.featured-image {
    width: 80%;
    margin-top: 30px;
}

.blog-title {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}

.code-preview{
    background-color: #1e1e3f;
}

.showCodeBtn {
    color: #00AEEF;
    font-size: 80%;
    padding: 0;
    border: none;
    background: none;  
}

.hide-section {display: none}

.hide-sources-row{
    margin-bottom: 20px;
}

.notshowing .code-section {
    display: none;
}