.memo-table {
   
    margin-top: 10px;
    margin-bottom: 20px;
    td {
        border: 1px solid #999;
        width: 3em;
     
    }
    .header {
        font-weight: bold;
    }
    .invalid-entry{
        background-color: #dee0e1 ;
    }
}

.code-snip {
    background-color: #f7f9fa;
    padding-left: 4px;
    padding-right: 4x;
    margin-left: 3px;
    margin-right: 3px;
    font-family: monospace;
    color: #546e7a;
    border-radius: 3px;
}

.math {
    margin-right: 4px;
}
.leet {
    padding: 90px;

    .explaination {
        margin-top: 10px;

        .solution-title {
            font-weight: bold;
            margin-top: 5px;
            margin-bottom: 5px;
            font-size: 20px;
        }

        .solution-section {
            margin-bottom: 20px;
        }
    }

    .leet-title {
        font-size: 30px;
        font-weight: bold;
        padding-top: 10px;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .example-num {
        font-weight: bold;
    }

    .example-if {
        margin-left: 30%;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .example {

        margin-top: 10px;
        padding: 20px;
        background-color: #f7f9fa;
        margin-left: 10px;
        margin-bottom: 20px;

        .ex-heading {
            font-weight: bold;
            margin-right: 6px;
        }
    }
}